import OrderItems from '../OrderItems/OrderItems';
import c from 'classnames';
import { FC } from 'react';
import PayWithFordPay from '../PayWithFordPay/PayWithFordPay';
import CheckoutDetailsModel from '../../models/CheckoutDetailsModel';
import {
  DesktopItems,
  FdsLayoutRight,
  MessagePaid,
} from '../../pages/CheckoutFordPay/CheckoutFordPayStyle';

export const RightPanelCheckout: FC<CheckoutDetailsModel> = props => {
  const { checkout, paid, color } = props;
  const showPayment = checkout && checkout.azureAdToken && checkout.paymentInfoToken && !paid;

  return (
    <FdsLayoutRight paid={checkout.checkoutStatus === 'paid'}>
      <DesktopItems>
        <OrderItems
          dueDate={checkout.dueDate}
          customerAddress={checkout.customerAddressDto}
          customerName={checkout.customerName}
          orderArray={checkout.repairOrderList}
          color={color}
        />
      </DesktopItems>
      {paid && (
        <MessagePaid>
          Thank you for your payment.
          <br />
          We've emailed you a receipt for your records.
        </MessagePaid>
      )}
      {showPayment && (
        <PayWithFordPay azureJwt={checkout.azureAdToken} paymentToken={checkout.paymentInfoToken} />
      )}
    </FdsLayoutRight>
  );
};
