import axios, { AxiosError, AxiosResponse } from 'axios';
import CheckoutModel from '../models/CheckoutModel';
import QuoteModel from '../models/QuoteModel';

export interface ResponseQuote {
  status: number;
  data: {
    error: string;
    message: string;
  };
}

const { REACT_APP_API_URL } = process.env;

export const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    'X-App-Origin': 'WEB' 
  }
});

const responseBody = (response: AxiosResponse) => response.data.content;
const responseError = (response: AxiosError) => response.response;
const requests = {
  get: async (url: string) => {
    try {
      const response = await instance.get(url);
      return responseBody(response);
    } catch (error) {
      return responseError;
    }
  },
  post: async (url: string, typeName: string) => {
    try {
      const response = await instance.post(url, { typeName });
      return responseBody(response);
    } catch (error) {
      return responseError;
    }
  },
  postFinalize: async (url: string) => {
    try {
      const response = await instance.post(url);
      return responseBody(response);
    } catch (error) {
      return responseError;
    }
  },
};

export const Services = {
  getCheckout: async (jwtToken: string): Promise<CheckoutModel> =>
    requests.get(`/checkout/${jwtToken}`),
  getCheckoutFordPay: async (jwtToken: string): Promise<CheckoutModel> =>
    requests.get(`/checkout/${jwtToken}`),
  getQuote: async (jwtToken: string): Promise<QuoteModel> => requests.get(`/approval/${jwtToken}`),
  getPDFQuote: async (jwtToken: string): Promise<string> =>
    requests.get(`/approval/${jwtToken}/pdf`),
  acceptQuote: async (jwtToken: string, typeName: string): Promise<ResponseQuote> =>
    requests.post(`/approval/${jwtToken}/accept`, typeName),
  finalizeInvoiceCheckout: async (jwtToken: string): Promise<CheckoutModel> =>
  requests.postFinalize(`/checkout/${jwtToken}/finalizeInvoice`), 
};
