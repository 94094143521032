import { Stripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import CheckoutModel from '../models/CheckoutModel';
import QuoteModel from '../models/QuoteModel';
import { Services } from '../services/api';
import { initializeStripe } from '../components/Api/Stripe';

interface StripeCheckoutProps {
  jwtFromUrl: string | undefined;
}

export function useStripeCheckout(props: StripeCheckoutProps) {
  const { jwtFromUrl } = props;
  const [clientSecret, setClientSecret] = React.useState('');
  const [checkout, setCheckout] = React.useState<CheckoutModel>();
  const [isErrorr, setIsError] = React.useState<boolean>(false);
  const [stripe, updateStripe] = React.useState<Stripe | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [paid, setPaid] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (jwtFromUrl) {
          const data = await Services.getCheckout(jwtFromUrl);
          setCheckout(data);

          if (data.checkoutStatus === 'paid') {
            setPaid(true);
          }

          setClientSecret(data.paymentIntentSecretId);

          const result = await initializeStripe(data.accountId);
          updateStripe(result);
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [jwtFromUrl]);

  return { checkout, clientSecret, stripe, loading, paid, isErrorr, setPaid, setIsError };
}

export function useStripeApproval(props: StripeCheckoutProps) {
  const { jwtFromUrl } = props;
  const [quote, setQuote] = React.useState<QuoteModel>();
  const [pdf, setPdf] = React.useState('');
  const [isErrorr, setIsError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [accept, setAccept] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (jwtFromUrl) {
          const data = await Services.getQuote(jwtFromUrl);
  
          if (data.quoteDto.status === 'accepted') {
            setAccept(true);
          }
          const pdfData = await Services.getPDFQuote(jwtFromUrl);
          setPdf(pdfData);
  
          setQuote(data);
        }
      } catch (err) {
        console.warn('Error loading Stripe Approval:', err);
        setIsError(true);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [jwtFromUrl]);
  
  return { quote, loading, isErrorr, setIsError, pdf, accept, setAccept };
}

export function useFordPayCheckout(props: StripeCheckoutProps) {
  const { jwtFromUrl } = props;
  const [stripe, updateStripe] = React.useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = React.useState('');
  const [azureJwt, setAzureJwt] = React.useState('');
  const [paymentToken, setPaymentToken] = React.useState('');
  const [isErrorr, setIsError] = React.useState<boolean>(false);
  const [checkout, setCheckout] = React.useState<CheckoutModel>();

  const [paid, setPaid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (jwtFromUrl) {
          const data = await Services.getCheckoutFordPay(jwtFromUrl);
          setCheckout(data);
          setAzureJwt(data.azureAdToken);
          setPaymentToken(data.paymentInfoToken);
          
          if (data.checkoutStatus === 'paid') {
            setPaid(true);
          }
          
          setClientSecret(data.paymentIntentSecretId);
          
          const result = await initializeStripe(data.accountId);
          updateStripe(result);
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [jwtFromUrl]);

  return {
    checkout,
    clientSecret,
    stripe,
    loading,
    paid,
    isErrorr,
    setPaid,
    setIsError,
    azureJwt,
    paymentToken,
  };
}
