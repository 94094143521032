import { FMCAccordion } from '@ford/ford-design-system';
import { FC, useEffect, useRef } from 'react';
import OrderItems from '../OrderItems/OrderItems';
import Items from '../Items/Items';
import c from 'classnames';
import { ColorsRules, ColorsRulesBackGround, DownloadButton, IconAccept } from '../Styles/Style';
import { DetailsProps } from '../../models/DetailsPropsModel';
import { MobileItems, ServiceDetailsPanel, ServiceDetailsPanelTitle, ServiceDetailsTotal, Title, Value } from './DetailsStyle';

const Details: FC<DetailsProps> = props => {
  const {
    orderAmountDue,
    orderArray,
    orderPdfLink,
    message,
    dueDate,
    customerAddress,
    customerName,
    items,
    color,
    accept,
  } = props;

  const accordionRef = useRef<HTMLDivElement>(null);
  const accordion = useRef();

  useEffect(() => {
    if (!accordion.current && items.length > 0) {
      const currentAccordion = new FMCAccordion(accordionRef.current);
      accordion.current = currentAccordion;
      currentAccordion.expandAll();
    } else {
      accordion.current = undefined;
    }
  }, [items]);

  return (
    <>
      <Title>
        <ColorsRules color={color}>{message} </ColorsRules>
        {accept && (
          <IconAccept className="fds-icon fds-icon--24 fds-font--ford-icons__check-filled" />
        )}
      </Title>

      <h3>
        <ColorsRules color={color}>${orderAmountDue.toFixed(2)}</ColorsRules>
      </h3>
      <MobileItems>
        <OrderItems
          dueDate={dueDate}
          customerAddress={customerAddress}
          customerName={customerName}
          orderArray={orderArray}
          color={color}
        />
      </MobileItems>
      <ServiceDetailsPanel
        hidden={!items.length}
        ref={accordionRef}
        className={[
          'js-fmc-accordion',
          'fmc-accordion',
          'fds-color__bg--light',
          'fmc-accordion--light'
        ].join(' ')}>
        <div className="fmc-accordion__panel fmc-accordion__panel--expanded">
          <ServiceDetailsPanelTitle
            type="button"
            className={'fmc-accordion__button'}
            id="panel-1-button"
            aria-controls="panel-1-content">
            <span className={'fmc-accordion__button-title'}>
              <ColorsRules color={color}>Service Details</ColorsRules>
            </span>
            <span className="fmc-accordion__button-expand fds-icon fds-color__text--primary"></span>
          </ServiceDetailsPanelTitle>
          <div className="fmc-accordion__body">
            <div
              className="fmc-accordion__content fds-color__bg--white fds-color__text--gray2"
              id="panel-1-content"
              role="region"
              aria-hidden="false">
              <div className="fmc-type--body1">
                <Items items={items} />
                <ServiceDetailsTotal>
                  <span>
                    <ColorsRules color={color}>Total</ColorsRules>
                  </span>
                  <Value>
                    <ColorsRules color={color}>${orderAmountDue.toFixed(2)}</ColorsRules>
                  </Value>
                </ServiceDetailsTotal>
              </div>
            </div>
          </div>
        </div>
      </ServiceDetailsPanel>
      {orderPdfLink && (
        <DownloadButton>
          <button
            data-testid="downloadPdf"
            onClick={() => window.open(orderPdfLink)}
            className="fmc-text-button fds-m--t-2">
            <ColorsRulesBackGround color={color}>
              <span className={'fds-icon fds-icon--offset-left'}></span>
            </ColorsRulesBackGround>
            <ColorsRules color={color}>Download PDF</ColorsRules>
          </button>
        </DownloadButton>
      )}
    </>
  );
};

export default Details;
