import { FC, Key } from 'react';
import { TaxRatesModel } from '../../models/TaxModel';
import { ServiceDetailsPanelDivider, ServiceDetailsPanelItem, Value } from '../Details/DetailsStyle';

interface ItemsProps {
  taxRatesProps: TaxRatesModel[];
  amountProps: number;
}

const TaxRates: FC<ItemsProps> = props => {
  const { taxRatesProps, amountProps } = props;

  function taxValue(amount: number, percentage: number) {
    return amount * (percentage / 100);
  }

  return (
    <>
      {taxRatesProps.map((taxAmount: { displayName: string; percentage: number }, index: Key) => (
        <ServiceDetailsPanelItem key={index + 'a'}>
          <span>{`${taxAmount.displayName} ${taxAmount.percentage}%`}</span>
          <Value>
            ${taxValue(amountProps, taxAmount.percentage).toFixed(2)}
          </Value>
          <ServiceDetailsPanelDivider></ServiceDetailsPanelDivider>
        </ServiceDetailsPanelItem>
      ))}
    </>
  );
};

export default TaxRates;
