import styled from 'styled-components';

export const Label = styled.label`
  font-family: var(--fmc-type__base-font);
  font-size: 11px;
  color: var(--fds-color--gray3);
`;

export const CellLabel = styled.td`
  font-family: var(--fmc-type__base-font);
  font-size: 16px;
  color: var(--fds-color--gray2) !important;
`;
