import { ActivityIndicator } from '../Loading/ActivityIndicator';
import { loadingSpinnerBig } from '../Styles/Style';
import { Header as HeaderSC, Logo } from './HeaderStyle';

interface HeaderProps {
  dealerLogoUrl: string;
  loading: boolean;
}

export default function Header({ dealerLogoUrl, loading }: HeaderProps) {
  return (
    <>
      <HeaderSC>
        <Logo src={dealerLogoUrl} alt="Logo" />
      </HeaderSC>
      {loading && <ActivityIndicator style={loadingSpinnerBig} />}
    </>
  );
}
