import React, { ChangeEvent, FC } from 'react';
import ApprovePaymentModel from '../../models/ApprovePaymentModel';
import { Services } from '../../services/api';
import {
  InvalidInputMessage,
  PayButton,
  stripeElementStyle,
  StyleNameOnCard,
} from '../Styles/Style';

const ApprovePayment: FC<ApprovePaymentModel> = props => {
  const {
    setInvalidNameMessage,
    setTypeName,
    typeName,
    invalidNameMessage,
    orderAmountDue,
    setAccept,
    jwtToken,
    setErrorMessage,
    setError,
  } = props;

  const [isError, setIsError] = React.useState(true);

  const StripeNameElementChangeEvent = {
    placeholder: 'First Last',
    style: stripeElementStyle,
  };

  async function approveQuote(nameType: string, tokenJWT: string) {
    try {
      const res = await Services.acceptQuote(tokenJWT, nameType);
      const INVALID_STATUS = 400
      const isDraftQuote = res.status === INVALID_STATUS && res.data.message 
      
      if (isDraftQuote) {
        setError(true);
        setIsError(true);
        setErrorMessage(
          'This quote cannot be accepted because it is currently in `draft` status. It must be in `open` status to be accepted. ',
        );
      } else {
        setError(false);
        setAccept(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const acceptQuote = () => {
    const pattern = '\\d';
    const exactMatch = new RegExp(pattern);
    if (typeName.match(exactMatch) && isError) {
      setIsError(true);
      setInvalidNameMessage('name only allows letters');
    } else if (typeName.charAt(0) === ' ' && isError) {
      setIsError(true);
      setInvalidNameMessage('name cannot be blank');
    } else if (typeName === '' && !typeName && isError) {
      setInvalidNameMessage('name cannot be blank');
      setIsError(true);
    } else {
      setIsError(false);
      approveQuote(typeName, jwtToken);
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvalidNameMessage('');
    setTypeName(e.target.value);
  };

  return (
    <>
      <StyleNameOnCard>
        <label htmlFor="typeName">Type name</label>
        <input
          onChange={handleNameChange}
          autoFocus
          placeholder={StripeNameElementChangeEvent.placeholder}
          type="text"
          aria-label="type-name"
          value={typeName}
          name="First Last"
        />
        {invalidNameMessage && <InvalidInputMessage>{invalidNameMessage}</InvalidInputMessage>}
        <PayButton color={'primary'}>
          <button
            className="fmc-button"
            onClick={() => acceptQuote()}
            data-testid="button-accept"
            style={{ scale: '1.1' }}>
            Approve ${orderAmountDue.toFixed(2)} quote
          </button>
        </PayButton>
        <PayButton color={'gray2'}>
          <button
            onClick={() => window.open('tel:1235555555')}
            data-testid="button-call"
            className="fmc-button">
            Call 123.555.5555 for help
          </button>
        </PayButton>
      </StyleNameOnCard>
    </>
  );
};

export default ApprovePayment;
