import OrderItems from '../OrderItems/OrderItems';
import { FC, useState } from 'react';
import ApprovePayment from '../ApprovePayment/ApprovePayment';
import { Title } from '../Styles/Style';
import QuoteDetailsModel from '../../models/QuoteDetailsModel';
import { DesktopItems, Divider, FdsLayoutRightWithContainer } from '../../pages/CheckoutFordPay/CheckoutFordPayStyle';

export const RightPanelApproval: FC<QuoteDetailsModel> = props => {
  const { quote, color, accept, setAccept, jwtToken, setError, setErrorMessage } = props;
  const [typeName, setTypeName] = useState('');
  const [invalidNameMessage, setInvalidNameMessage] = useState(' ');

  const orderArray: string[] = quote ? [quote.quoteDto.number] : [''];

  return (
    <FdsLayoutRightWithContainer>
      <DesktopItems>
        <OrderItems
          dueDate={quote.quoteDto.expiresAt}
          customerAddress={quote.quoteDto.customerDto.customerAddress}
          customerName={quote.quoteDto.customerDto.customerName}
          orderArray={orderArray}
          color={color}
        />
      </DesktopItems>
      {accept && (
        <Title color={color}>
          <span>Thank you for approving our quote. Now we'll get you back on the road.</span>
        </Title>
      )}
      {!accept && (
        <Title color={color}>
          <span>Submit Approval</span>
          <Divider/>
          <br />
          <p>By submitting this form you agree to pay cost upon completion of the work</p>
          <br />
          <ApprovePayment
            jwtToken={jwtToken}
            setAccept={setAccept}
            setInvalidNameMessage={setInvalidNameMessage}
            setTypeName={setTypeName}
            typeName={typeName}
            invalidNameMessage={invalidNameMessage}
            orderAmountDue={quote.quoteDto.amountTotal}
            setError={setError}
            setErrorMessage={setErrorMessage}
          />
        </Title>
      )}
    </FdsLayoutRightWithContainer>
  );
};
