import styled from 'styled-components';

export const Header = styled.header`
  height: 100%;
  justify-content: left;
  display: flex;
  padding: 15px;
  align-items: center;
  padding-left: 20px;
`;

export const Logo = styled.img`
  display: block;
  height: 100%;
  margin-left: 10vw;

  @media only screen and (hover: none) and (pointer: coarse) {
    display: block;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;