import { StripeElementStyle } from '@stripe/stripe-js';
import styled from 'styled-components/macro';

interface ColorsProps {
  color: string;
}
function getColors(color: string) {
  const Colors = {
    primary: 'var(--fds-color--primary)',
    secondary: 'var(--fds-color--secondary)',
    tertiary: 'var(--fds-color--tertiary)',
    fourth: 'var(--fds-color--fourth)',
    white: 'var(--fds-color--white)',
    black: 'var(--fds-color--black)',
    gray1: 'var(--fds-color--gray1)',
    gray2: 'var(--fds-color--gray2)',
    gray3: 'var(--fds-color--gray3)',
    red: 'var(--fds-color--error1)',
  };
  return Colors[color as keyof typeof Colors];
}

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: '#00095b',
    fontFamily: 'FordAntenna, Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#fce883',
    },
  },
};

export const TermsAndConditionsStyled = styled.div`
  margin-top: 50%;
  margin-bottom: 5vh;
  @media only screen and (hover: none) and (pointer: coarse) {
    display: none !important;
  }
`;

export const DownloadButton = styled.span`
  font-stretch: condensed;
  font-weight: 600;
  font-size: 14px;
  @media only screen and (hover: none) and (pointer: coarse) {
    padding-left: 25vw;
    margin-left: -4vw;
    margin-right: auto;
    display: inline;
  }
`;

export const TermsAndConditionsStyledMobile = styled.div`
  margin-top: 50%;
  margin-bottom: 5vh;
  display: none;
  @media only screen and (hover: none) and (pointer: coarse) {
    display: block !important;
    text-align: center;
    margin-top: 20% !important;
    width: auto;
    grid-column-end: span 4;
  }
`;

export const IconAccept = styled.span`
  font-size: 2rem;
  color: var(--fds-color--success1);
`;

export const InvalidInputMessage = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: 12px;
  font-family: var(--fmc-type__base-font);
  color: #eb1c26;
  width: 100%;
`;

export const ColorsRules = styled.span<ColorsProps>`
  color: ${item => getColors(item.color)};
`;

export const ColorsRulesBackGround = styled.span<ColorsProps>`
  span {
    mask: url(../../../public/assets/Communication&Connectivity_Download_24.svg) no-repeat;
    mask-size: cover;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    background-color: ${item => getColors(item.color)};
  }
`;
export const Title = styled.div<ColorsProps>`
  text-align: left;
  margin-top: 5vh;
  span {
    text-align: center;
    font-family: var(--fmc-type__base-font);
    color: ${item => getColors(item.color)};
    font-weight: var(--fmc-type__weight-medium);
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
  }
  p {
    text-align: center;
    font-family: var(--fmc-type__base-font);
    color: ${item => getColors(item.color)} !important;
    font-weight: var(--fmc-type__weight-medium);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 28px;
  }
`;

export const StyleNameOnCard = styled.div`
  text-align: left !important;
  input {
    font-family: var(--fmc-type__base-font);
    width: 100%;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 40px;
    padding-left: 20px;
    color: var(--fds-color--primary);
    margin-bottom: 20px;
  }
`;

export const PayButton = styled.div<ColorsProps>`
  margin-left: auto;
  margin-right: auto;
  display: block !important;
  margin-top: 2rem;
  button {
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    margin-bottom: 1rem;
    --bg-color: ${item => getColors(item.color)} !important;
  }
  button:hover {
    --bg-color-hover: ${item => getColors(item.color)} !important;
  }
`;

export const FordPayUIStyle = styled.div`
  && {
    font-family: var(--fmc-type__base-font);
    text-align: center;
    color: var(--fds-color--gray3);
    background-color: var(--fds-color--white);
    padding: 0.75rem;
    margin-block: 1em;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: 0.06rem;
  }

  && a {
    color: var(--fds-color--primary);
    text-decoration: underline;
  }

  && a:hover {
    color: var(--fds-color--secondary);
    text-decoration: underline;
  }

  && a:focus {
    color: var(--fds-color--secondary);
    text-decoration: underline;
  }

  && h2 {
    color: var(--fds-color--primary);
    margin-block: 1rem;
    letter-spacing: 0;
    text-align: left;
    font-weight: normal;
  }

  && h2.checkout-error {
    font-size: 2.5em;
  }

  && h2.new-card {
    font-size: 1.3rem;
  }

  && p.checkout-error {
    text-align: left;
    font-size: 1em;
    letter-spacing: 0;
  }
`;

export const loadingSpinnerBig = {
  minHeight: '18.5rem',
  display: 'flex',
};

export const Line = styled.div<{
  diameter: string;
  lineColor: string;
  offset: string;
}>`
  && {
    --circle-diameter: ${(props: { diameter: string }) => props.diameter};
    --indicator-color: ${(props: { lineColor: string }) => props.lineColor};
    --offset: ${(props: { offset: string }) => props.offset};

    position: relative;
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    margin: auto;
  }

  &&::before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    margin-left: calc(var(--circle-diameter) / -2);
    border-radius: var(--circle-diameter);
    border-width: 0.1rem;
    border-style: solid;
    border-color: var(--indicator-color);
  }
`;

export const Spinner = styled.div<{
  diameter: string;
  activityLineColor: string;
  offset: string;
  loaderWeight: string;
}>`
  && {
    --activity-line-color: ${(props: { activityLineColor: string }) => props.activityLineColor};
    --loader-weight: ${(props: { loaderWeight: string }) => props.loaderWeight};
  }

  @keyframes inner-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  position: relative;

  &&::before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: calc(var(--circle-diameter) + var(--offset));
    height: calc(var(--circle-diameter) + var(--offset));
    left: 50%;
    top: calc(var(--offset) / -2);
    margin-left: calc((var(--circle-diameter) + var(--offset)) / -2);
    border-radius: var(--circle-diameter);
    border-style: solid;
    border-color: transparent;
    border-width: var(--loader-weight);
    border-top-color: var(--activity-line-color);
    animation: inner-spin 2.5s linear infinite;
  }
`;
