import { useState, FC } from 'react';
import c from 'classnames';
import { Title } from '../../pages/CheckoutFordPay/CheckoutFordPayStyle';

interface PayWithFordPayProps {
  paymentToken: string;
  azureJwt: string;
}

const PayWithFordPay: FC<PayWithFordPayProps> = props => {
  const { azureJwt, paymentToken } = props;
  const redirectURL = `${location.origin}/checkoutfinalized`;
  return (
    <div style={{ marginTop: '20px' }}>
      <Title>Pay With FordPay</Title>
      <hr className={c('fmc-divider--horizontal')} />
      <fordpay-checkout
        azure-ad-token={azureJwt.replace(/"/g, '')}
        payment-info-token={paymentToken}        
        redirect-url={redirectURL || ''}
        language='en-US'
      />
    </div>
  );
};

export default PayWithFordPay;
