import { FC } from 'react';
import { ColorsRules } from '../Styles/Style';
import CheckoutItems from '../../models/OrderItems';
import { CellLabel } from './OrderItemsStyle';

const OrderItems: FC<CheckoutItems> = props => {
  const { dueDate, customerName, orderArray, customerAddress, color } = props;

  const getRepairOrderLabel = () => {
    if (orderArray.length > 1) {
      return "Repair Order's #";
    } else {
      return 'Repair Order #';
    }
  };

  function formatDueDate(date: string) {
    date = date + 'T12:00:00Z'
    if (!date) return '-';
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: '2-digit' });
  }
  
  return (
    <div className="invoiceItems">
      <table className="fmc-table fmc-table--no-borders">
        <tbody>
          <tr>
            <CellLabel>Service Date</CellLabel>
            <td>
              <ColorsRules color={color}>
              {formatDueDate(dueDate)} 
              </ColorsRules>
            </td>
          </tr>
          <tr>
            <CellLabel>Your Name</CellLabel>
            <td>
              <ColorsRules color={color}>{customerName ? customerName : '-'}</ColorsRules>
            </td>
          </tr>
          <tr>
            <CellLabel>Service Address</CellLabel>
            <td>
              <ColorsRules color={color} data-testid="addressLine1">
                {customerAddress ? customerAddress.line1 : '-'}
              </ColorsRules>
            </td>
          </tr>
          {customerAddress && (
            <tr data-testid="addressLine2">
              <td></td>
              <td>
                <ColorsRules
                  color={
                    color
                  }>{`${customerAddress.state} ${customerAddress.postalCode}`}</ColorsRules>
              </td>
            </tr>
          )}
          <tr>
            <CellLabel>{getRepairOrderLabel()}</CellLabel>
            <td>
              {orderArray.map((item: string, i: number) => {
                return (
                  <div key={i}>
                    <ColorsRules color={color}>{item}</ColorsRules>
                    <br />
                  </div>
                );
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderItems;
