import { FC, Key } from 'react';
import TaxRates from '../TaxRates/TaxRates';
import { TaxRatesModel } from '../../models/TaxModel';
import { ServiceDetailsPanelDivider, ServiceDetailsPanelItem, Value } from '../Details/DetailsStyle';

interface ItemsProps {
  items: any;
}

const Items: FC<ItemsProps> = props => {
  const { items } = props;
  return (
    <>
      {items.map(
        (item: {
          id: Key;
          description: string;
          amount?: number;
          amountTotal: number;
          taxRates: TaxRatesModel[];
        }) => {
          let amount = 0;
          amount = item.amount ? item.amount : item.amountTotal;

          return (
            <div key={item.id + 'b'}>
              <ServiceDetailsPanelItem key={item.id}>
                <span>{item.description}</span>
                <Value>${amount.toFixed(2)}</Value>
                <ServiceDetailsPanelDivider></ServiceDetailsPanelDivider>
              </ServiceDetailsPanelItem>
              {item.taxRates && <TaxRates amountProps={amount} taxRatesProps={item.taxRates} />}
            </div>
          );
        },
      )}
    </>
  );
};

export default Items;
