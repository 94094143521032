import styled from 'styled-components';

type FdsLayoutRightProps = {
  paid?: boolean;
}

export const FdsLayoutLeft = styled.div.attrs({
  className: 'fds-layout-grid__cell--span-6'
})`
  background: var(--fds-color--fourth);
  margin-left: 10vw;
`;

export const LayoutGrid = styled.div.attrs({
  className: 'fds-layout-grid'
})`
  padding: 0 !important;
`;

export const FdsLayoutRight = styled.div.attrs({
  className: 'fds-layout-grid__cell--span-6'
})<FdsLayoutRightProps>`
  background: var(--fds-color--white);
  padding-right: 10vw;
  padding-left: 10vw;
  padding-bottom: ${props => props.paid ? "12vw" : "2vw"};
  padding-top: ${props => props.paid ? "4vw" : "3vw"};

  @media only screen and (hover: none) and (pointer: coarse) {
    padding-bottom: 5vw;
  }
`;

export const LayoutContainer = styled.div`
  padding-top: 3vw;
`;

export const FdsLayoutRightWithContainer = styled.div.attrs({
  className: 'fds-layout-grid__cell--span-6'
})`
  background: var(--fds-color--white);
  padding-right: 10vw;
  padding-left: 10vw;
  padding-bottom: 2vw;

  @media only screen and (hover: none) and (pointer: coarse) {
    padding-bottom: 5vw;
  }

  && .paid {
    padding-bottom: 12vw;
    padding-top: 4vw;
  }

  && .not-paid {
    padding-top: 3vw;
  }

  padding-top: 3vw;
`;

export const FdsLayoutLeftWithContainer = styled.div.attrs({
  className: 'fds-layout-grid__cell--span-6'
})`
  background: var(--fds-color--fourth);
  margin-left: 10vw;

  padding-top: 3vw;
`;

export const Header = styled.div`
  width: 100%;
  height: 12vh;
  box-shadow: 0 -9px 7px -7px #6c6c6c70 inset;
  background: var(--fds-color--white);
`;

export const TermsAndConditions = styled.div`
  padding-left: 10vw;
  margin-top: -7vw;

  @media only screen and (hover: none) and (pointer: coarse) {
    text-align: center;
    padding-top: 10vw;
    padding-left: 0;
    padding-bottom: 10vw;
    background: var(--fds-color--white);
  }
`;

export const TermsAndConditionsPaid = styled.div`
  padding-left: 10vw;
  margin-top: -6.5vw;

  @media only screen and (hover: none) and (pointer: coarse) {
    text-align: center;
    padding-top: 10vw;
    padding-left: 0;
    padding-bottom: 10vw;
    background: var(--fds-color--white);
  }
`;

export const MessagePaid = styled.p`
  font-weight: var(--fmc-type__weight-bold);
  font-size: 18px;
  color: var(--fds-color--primary);
  padding: 0.7rem 1.6rem 0.8rem;
`;

export const LayoutContainerPaid = styled.div`
  padding-bottom: 12vw;
  padding-top: 4vw;
`;

export const DesktopPaid = styled.div`
  padding-bottom: 3vw;
`;

export const DesktopItems = styled.div`
  display: block !important;

  @media only screen and (hover: none) and (pointer: coarse) {
    display: none !important;
  }
`;

export const PayButton = styled.div`
  margin-left: auto;
  width: 100%;
  margin-right: auto;
  display: block !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const ButtonMobile = styled.div`
  display: none !important;

  @media only screen and (hover: none) and (pointer: coarse) {
    display: block !important;
    border: 0;
    cursor: pointer !important;
    max-height: 64px;
    min-height: 32px;
    position: relative !important;
    height: 30%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1 !important;
  }
`;

export const Title = styled.div`
  font-family: var(--fmc-type__base-font);
  color: var(--fds-color--primary);
  font-weight: var(--fmc-type__weight-medium);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
`;

export const Divider = styled.hr.attrs({
  className: 'fmc-divider--horizontal'
})`
  margin-top: 24px;
`;
