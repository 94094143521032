import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { useEffect, useState } from 'react';
import { LeftPanelQuote } from '../../components/LeftPanel/LeftPanelQuote';
import { Message } from '../../components/Message/Message';
import { useStripeApproval } from '../../hooks/useStripe';
import { RightPanelApproval } from '../../components/RightPanel/RightApprovalPanel';
import { base64toBlob } from '../../utils/Base64ToPDF';
import { TermsAndConditionsStyledMobile } from '../../components/Styles/Style';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import { Header as HeaderSC, LayoutGrid }  from '../../components/Styles/ApprovalStyle';
 
const fordLogo = "/assets/fordLogo/Ford_logo_flat.svg.png";

function Approval() {
  const color = 'primary';
  const [urlPDF, setUrlPDF] = useState<string>('');
  const { jwtFromUrl } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { quote, loading, isErrorr, pdf, accept, setAccept } = useStripeApproval({ jwtFromUrl });

  const [dealerLogoUrl, setDealerLogoUrl] = useState<string>(fordLogo);

  const messageApprovals = accept ? "You've Approved" : 'Approve your service';

  useEffect(() => {
    const blobCall = async () => {
      const blob = await base64toBlob(pdf);
      const url = URL.createObjectURL(blob);
      setUrlPDF(url);
    };
    blobCall();
  }, [pdf]);

  useEffect(() => {
    if (quote && quote.accountDto.accountSettingsDto.settingsBrandingDto.logo) {
      setDealerLogoUrl(quote.accountDto.accountSettingsDto.settingsBrandingDto.logo);
    }
  }, [quote]);

  const orderArray: string[] = quote ? [quote.quoteDto.number] : [''];

  return (
    <div>
      <HeaderSC>
        <Header loading={loading} dealerLogoUrl={dealerLogoUrl} />
      </HeaderSC>

      {!loading && isErrorr && (
        <Message error={isErrorr} message={'Quote not found! Please check the quote link.'} />
      )}

      {!loading && error && <Message error={error} message={errorMessage} />}
      {accept && <Message error={isErrorr} message={'This quote has already been accepted.'} />}
      {!loading && quote && jwtFromUrl && (
        <LayoutGrid>
          <div className="fds-layout-grid__inner">
            <LeftPanelQuote
              quoteAmountDue={quote.quoteDto.amountTotal}
              dueDate={quote.quoteDto.expiresAt}
              accept={accept}
              customerAddress={quote.quoteDto.customerDto.customerAddress}
              customerName={quote.quoteDto.customerDto.customerName}
              quoteNumber={orderArray}
              message={messageApprovals}
              quotePdfLink={urlPDF}
              items={quote.quoteDto.items}
              color={color}
            />

            <RightPanelApproval
              accept={accept}
              setAccept={setAccept}
              quote={quote}
              color={color}
              jwtToken={jwtFromUrl}
              setError={setError}
              setErrorMessage={setErrorMessage}
            />

            <TermsAndConditionsStyledMobile>
              <TermsAndConditions />
            </TermsAndConditionsStyledMobile>
          </div>
        </LayoutGrid>
      )}
    </div>
  );
}

export default Approval;
