import styled from 'styled-components';

export const Title = styled.h1.attrs({
  className: 'fmc-type--subtitle1'
})`
  margin-top: 40px;
  margin-bottom: 10px;
  font-stretch: condensed;
  font-size: 18px !important;
  font-weight: var(--fmc-type__weight-bold) !important;
`;

export const MessageQuotes = styled.div`
  color: var(--fds-color--gray2) !important;
`;

export const MessageInvoice = styled.div`
  color: var(--fds-color--primary) !important;
`;

export const ServiceDetailsPanel = styled.div`
  margin-top: 40px;
  width: 90% !important;
`;

export const ServiceDetailsPanelTitle = styled.button`
  font-weight: var(--fmc-type__weight-bold) !important;
`;

export const ServiceDetailsPanelItem = styled.div`
  font-family: var(--fmc-type__base-font);
  font-size: 11px;
  letter-spacing: 1px;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
`;

export const ServiceDetailsPanelSubItem = styled.div`
  font-family: var(--fmc-type__base-font);
  font-size: 11px;
  letter-spacing: 1px;
  margin-left: 20px;
`;

export const ServiceDetailsPanelDivider = styled.hr.attrs({
  className: 'fmc-divider--horizontal'
})`
  --divider-color: var(--fds-color--black-20);

  &&.fmc-divider--horizontal {
    --divider-color: var(--fds-color--black-40);
  }
`;

export const ServiceDetailsTotal = styled.div`
  color: var(--fds-color--primary);
  font-weight: var(--fmc-type__weight-bold);
  margin-top: 20px;
`;

export const Value = styled.span`
  float: right;
`;

export const DownloadPdfTextLink = styled.div`
  font-stretch: condensed;
  font-weight: 600;
  font-size: 14px;

  @media only screen and (hover: none) and (pointer: coarse) {
    text-align: center;
  }
`;

export const DownloadIcon24x24 = styled.div`
  mask: url(../../../public/assets/app_exclusive/images/Communication&Connectivity_Download_24.svg)
    no-repeat;
  mask-size: cover;
  height: 24px;
  width: 24px;
  background-color: var(--fds-color--primary);
  vertical-align: middle;
`;

export const DownloadIcon24x24Gray2 = styled.div`
  mask: url(../../../public/assets/app_exclusive/images/Communication&Connectivity_Download_24.svg)
    no-repeat;
  mask-size: cover;
  height: 24px;
  width: 24px;
  color: red;
  background-color: var(--fds-color--gray2);
  vertical-align: middle;
`;

export const MobileItems = styled.div`
  display: none;

  @media only screen and (hover: none) and (pointer: coarse) {
    display: block !important;
    margin-top: 5vw;

    table {
      --background-color: none !important;
    }

    table td {
      padding-left: 0;
      padding-right: 2vw;
    }

    .span {
      display: block !important;
      margin-left: auto !important;
      margin-right: auto !important;
      padding-right: 10vw !important;
    }
  }
`;