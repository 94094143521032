import React, { useEffect } from 'react';
import qs from 'query-string';
import { Stripe } from '@stripe/stripe-js';
import { Services } from '../../services/api';
import { useNavigate } from "react-router-dom";

const CheckoutFinalizedFordPay = () => {
  const [stripe] = React.useState<Stripe | null>(null);
  const navigate = useNavigate();

  const query = qs.parse(location.search);
  const {
    account_id,
    payment_type,
    payment_intent,
    redirect_status,
    payment_intent_client_secret,
  } = query ?? {};

  const jwtURL = sessionStorage.getItem('token') ?? "";
  async function confirmCardSetup() {
    await Services.finalizeInvoiceCheckout(jwtURL);
    navigate('/checkoutfordpay/' + jwtURL + '?&paid=' + redirect_status);
  }
  
  useEffect(() => {
    confirmCardSetup();
  }, [stripe, account_id, payment_type, payment_intent, payment_intent_client_secret]);

  return <div>{}</div>;
};

export default CheckoutFinalizedFordPay;
