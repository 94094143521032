import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Approval from './pages/Approval/Approval';
import useEffectOnce from './hooks/useEffectOnce';
import getJScript from './services/connectionFordPay';
import CheckoutFordPay from './pages/CheckoutFordPay/CheckoutFordPay';
import CheckoutFinalizedFordPay from './pages/CheckoutFordPay/CheckoutFinalizedFordPay';
import { Helmet } from 'react-helmet';

function App() {
  const urlFord = process.env.REACT_APP_SCRIPT_FORD_PAY;

  useEffect(() => {
    const root = document.documentElement;
    root.setAttribute('data-brand', 'ford');
  }, []);

  //rules for url .env
  const fordBundle = process.env.REACT_APP_SCRIPT_FORD_PAY ?? '';
  const urlApi = process.env.REACT_APP_API_URL ?? '';

  useEffectOnce(() => getJScript(urlFord || ''));

  const cspDirectives = {
    'default-src': ["'self'"],
    'script-src': ["'self'", fordBundle, 'https://js.stripe.com'],
    'connect-src': ["'self'", 'data:', urlApi, 'https://*.ford.com'],
    'font-src': ["'self'", 'https://*.ford.com'],
    'frame-src': ['https://js.stripe.com'],
    'style-src': ["'self'", "'unsafe-inline'"],
    'img-src': ["'self'", 'data:'],
    'frame-ancestors': ["'self'"],
    'form-action': ["'self'"],
  };

  return (
    <Router>
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content={generateCSP(cspDirectives)} />
      </Helmet>
      <Routes>
        <Route path="/checkoutfinalized/" element={<CheckoutFinalizedFordPay />} />
        <Route path="/checkoutfordpay/:jwtFromUrl" element={<CheckoutFordPay />} />
        <Route path="/approval/:jwtFromUrl" element={<Approval />} />
      </Routes>
    </Router>
  );
}

const generateCSP = (directives: { [key: string]: string[] }) => {
  return Object.entries(directives)
    .map(([directive, sources]) => `${directive} ${sources.join(' ')}`)
    .join('; ');
};

export default App;
