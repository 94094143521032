import { FC } from 'react';
import CheckoutDetails from '../Details/Details';
import c from 'classnames';
import { TermsAndConditionsStyled } from '../Styles/Style';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { DetailsProps } from '../../models/DetailsPropsModel';
import { FdsLayoutLeftWithContainer } from '../../pages/CheckoutFordPay/CheckoutFordPayStyle';

export const LeftPanel: FC<DetailsProps> = props => {
  const {
    orderAmountDue,
    orderArray,
    orderPdfLink,
    dueDate,
    customerAddress,
    customerName,
    items,
    message,
    color,
    accept,
  } = props;

  return (
    <FdsLayoutLeftWithContainer>
      <CheckoutDetails
        orderAmountDue={orderAmountDue}
        dueDate={dueDate}
        accept={accept}
        customerAddress={customerAddress}
        customerName={customerName}
        orderArray={orderArray}
        message={message}
        orderPdfLink={orderPdfLink}
        items={items}
        color={color}
      />
      <TermsAndConditionsStyled>
        <TermsAndConditions />
      </TermsAndConditionsStyled>
    </FdsLayoutLeftWithContainer>
  );
};
